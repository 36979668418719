<template>
  <div>
      <v-autocomplete
        v-model="selectedAddress"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        cache-items
        flat
        hide-no-data
        hint="e.g. 324 Queen Street, Brisbane City"
        prepend-icon="mdi-home-city-outline"
        label="Please enter an address to start searching"
      />
    <v-snackbar v-model="hasNoMatchingAddress" color="error">
      No matching addresses found. Please enter a new address.
    </v-snackbar>
  </div>
</template>

<script>
import { debounce } from "lodash";
import MapService from "@/services/MapService";

export default {
  data: () => ({
    loading: false,
    items: [],
    search: null,
    inputLot: "",
    inputPlan: "",
    selectedAddress: null,
    hasNoMatchingAddress: false,
    hasInflightQuery: false,
    abortController: null,
  }),
  watch: {
    search(val) {
      val &&
        val !== this.selectedAddress &&
        val.length >= 5 &&
        this.querySuggestions(val);
    },
    selectedAddress: debounce(async function (newAddress) {
      this.hasNoMatchingAddress = false;

      // Bug was found when there's an existing selected address and "Search by Lot Plan" was selected (which clears `this.selectedAddress` back to null).
      // This check was added so we only process fetching lot plans if `this.selectedAddress` is not null.
      if (newAddress != null) {
        this.loading = true;
        document.activeElement.blur();
        const lotplans = await this.$store.dispatch(
          "fetchAddressLotPlans",
          newAddress
        );
        if (lotplans.length === 1) {
          await this.$store.dispatch("setCurrentParcel", lotplans[0]);
          this.openLink();
        } else {
          this.clearParcel();
        }
        this.loading = false;
      }
      this.updateSelectedAddress();
    }, 1000),
  },
  // created() {
  //   if (this.$route.query && this.$route.query.selectedAddress) {
  //     // alert("queryString in searchAddress")
  //     this.selectedAddress = String(this.$route.query.selectedAddress);
  //   }
  // },
  methods: {
    resetForm() {
      this.selectedAddress = null;
      this.inputLot = "";
      this.inputPlan = "";
      this.items = [];
      this.clearParcel();
    },

    displayParcel() {
      this.$emit("display-parcel");
    },

    clearParcel() {
      this.$emit("clear-parcel");
    },

    updateSelectedAddress() {
      this.$emit("update-selected-address", this.selectedAddress);
    },

    querySuggestions: debounce(async function (inputAddress) {
      this.loading = true;

      if (this.hasInflightQuery) {
        this.abortController.abort();
        this.abortController = null;
        this.hasInflightQuery = false;
      }

      this.abortController = new AbortController();
      this.hasInflightQuery = true;

      try {
        const result = await MapService.fetchSuggestions(inputAddress, this.abortController.signal);
        this.items = result.items;
        this.hasNoMatchingAddress = result.hasNoMatchingAddress;
      } catch(error) {
          if (error.name === 'AbortError') {
            // eslint-disable-next-line no-console
            console.log('Fetch aborted');
          } else {
            // eslint-disable-next-line no-console
            console.error('Unexpected Error Encountered: ', error);
          }
      }
      this.loading = false;
      this.hasInflightQuery = false;
    }, 1000),
    openLink: function () {   
      window.open(window.location.origin + "/#/pages/search?selectedAddress=" +
        encodeURIComponent(this.selectedAddress), "_blank");
    }
  }
};
</script>
<style>
.theme--light.v-application { 
  background: #ffffff00;
  color: rgba(0, 0, 0, 0.87);
}

#app-bar {
  height: 0px;
  display: none;
}

#dashboard-core-footer {
  max-height: 0px;
  display: none;
}

.v-main {
  padding: 0px !important;
}
</style>